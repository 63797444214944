




























































































import { Component, Emit, Vue } from "vue-property-decorator";
import * as cls from "@/tool/_class";
import * as SchoolApi from "@/api/school";
import * as TestPaperApi from "@/api/testPaper";
import * as _ from "lodash";
import dayjs from "dayjs";
import ElementUI from "element-ui";

@Component({
  name: "SchoolContractEditor",
  components: {},
})
export default class extends Vue {
  @Emit("handleConfirmed") private handleConfirmed(
    data: cls.SchoolContract[]
  ) {}
  private schoolIds: string[] = [];
  private formData: cls.SchoolContract = new cls.SchoolContract();
  private dialogVisible: boolean = false;

  private schoolContractTypeOptions = cls.schoolContractTypeOptions;

  private resourceTemplateIdOptions: cls.ResourceTemplate[] = [];

  private schoolContractDaysOptions = [
    {
      value: 30,
      label: "30天",
    },
    {
      value: 60,
      label: "60天",
    },
    {
      value: 30 * 12,
      label: "一年",
    },
    {
      value: 30 * 36,
      label: "三年",
    },
    {
      value: 30 * 60,
      label: "五年",
    },
  ];

  private schoolContractDaysItemChecked(item: {
    value: number;
    label: string;
  }): boolean {
    const dd = dayjs(this.formData.schoolContractEndDate).diff(
      this.formData.schoolContractStartDate,
      "day"
    );
    const yy = dayjs(this.formData.schoolContractEndDate).diff(
      this.formData.schoolContractStartDate,
      "year"
    );
    switch (item.value) {
      case 60:
      case 30: {
        return item.value == dd;
      }
      case 30 * 12:
      case 30 * 36: {
        return item.value == yy;
      }
      case 30 * 60: {
        return item.value == yy;
      }
      default: {
        console.log(
          `switch default item:${JSON.stringify(
            item
          )}, diff day: ${dd}, diff year: ${yy}`
        );
      }
    }

    return false;
  }

  private schoolContractEndDateToNow(): string {
    return dayjs(this.formData.schoolContractEndDate).fromNow();
  }

  private handleDatePickerChange() {
    this.$forceUpdate();
  }

  private schoolContractDatesValidator(
    rule: any,
    value: string,
    callback: any
  ) {
    if (
      this.formData.schoolContractStartDate &&
      this.formData.schoolContractEndDate
    ) {
      callback();
    } else {
      callback(new Error(`请输入合约日期`));
    }
  }

  private rules = {
    schoolContractNo: [
      { required: true, message: "请输入合约编号", trigger: "blur" },
    ],
    schoolContractType: [
      { required: true, message: "请选择合约类型", trigger: "blur" },
    ],
    resourceTemplateId: [],
    schoolContractDates: [
      /*
      {
        type: "date",
        required: true,
        message: "请选择日期",
        trigger: "change",
      },

*/
      { validator: this.schoolContractDatesValidator, trigger: "blur" },
    ],
    contractContent: [],
  };

  private handleSchoolContractTypeChange(val: string) {
    const today: Date = dayjs(dayjs().format("YYYY-MM-DD")).toDate();
    if (!this.formData.schoolContractStartDate) {
      this.formData.schoolContractStartDate = today;
    }
    switch (val) {
      case cls.ContractType.trial: {
        //试用版
        this.formData.schoolContractEndDate = dayjs(today)
          .add(30, "day")
          .toDate();
        break;
      }
      case cls.ContractType.standard:
      case cls.ContractType.plus: {
        //正式版
        /*
        this.formData.schoolContractEndDate = dayjs(today)
          .add(1, "year")
          .toDate();
      */
        break;
      }
      default: {
        //
      }
    }
  }

  private async doSchoolContractDaysItemClick(item: {
    value: number;
    label: string;
  }) {
    let d1: Date = dayjs(dayjs().format("YYYY-MM-DD")).toDate();
    if (!this.formData.schoolContractStartDate) {
      //
    } else {
      d1 = dayjs(
        dayjs(this.formData.schoolContractStartDate).format("YYYY-MM-DD")
      ).toDate();
    }
    let d2!: Date;
    switch (item.value) {
      case 30:
      case 60: {
        d2 = dayjs(d1).add(item.value, "day").toDate();
        break;
      }
      case 30 * 12: {
        d2 = dayjs(d1).add(1, "year").toDate();
        break;
      }
      case 30 * 36: {
        d2 = dayjs(d1).add(3, "year").toDate();
        break;
      }
      case 30 * 60: {
        d2 = dayjs(d1).add(5, "year").toDate();
        break;
      }
      default: {
        return;
      }
    }
    this.$delete(this.formData, "schoolContractStartDate");
    this.$delete(this.formData, "schoolContractEndDate");
    this.$set(this.formData, "schoolContractStartDate", d1);
    this.$set(this.formData, "schoolContractEndDate", d2);
    /*
    this.formData.schoolContractStartDate = dayjs(
      dayjs(d1).format("YYYY-MM-DD")
    ).toDate();
    this.formData.schoolContractEndDate = dayjs(
      dayjs(d2).format("YYYY-MM-DD")
    ).toDate();
    */
    /*
    (this.$refs["schoolContractStartDate"] as ElementUI.DatePicker).focus();
    this.$forceUpdate();
    (this.$refs["schoolContractEndDate"] as ElementUI.DatePicker).focus();
    this.$forceUpdate();
    */

    console.log(
      `schoolContractStartDate: ${dayjs(
        this.formData.schoolContractStartDate
      ).format("YYYY-MM-DD")},schoolContractEndDate: ${dayjs(
        this.formData.schoolContractEndDate
      ).format("YYYY-MM-DD")}`
    );
  }

  private async __init() {
    this.resourceTemplateIdOptions.splice(
      0,
      this.resourceTemplateIdOptions.length
    );
    this.resourceTemplateIdOptions = (
      await TestPaperApi.getResourceTemplateList({
        curPage: 1,
        pageSize: 999,
      })
    ).data.items;

    this.formData = new cls.SchoolContract();
  }

  async showBatchNew(schoolIds: string[]) {
    await this.__init();
    this.schoolIds = schoolIds;
    if (!this.formData.schoolContractNo) {
      this.formData.schoolContractNo = `${dayjs().format("YYMMDD")}-`;
    }
    this.dialogVisible = true;
  }

  async show(schoolId: string, schoolContractId: string) {
    await this.__init();
    if (schoolContractId) {
      const { data } = await SchoolApi.getSchoolContractList({
        schoolContractId,
        curPage: 1,
        pageSize: 1,
      });
      if (data.items.length > 0) {
        _.merge(this.formData, data.items[0]);
      }
    }
    this.schoolIds = [schoolId];
    this.formData.schoolId = schoolId;
    if (!this.formData.schoolContractNo) {
      this.formData.schoolContractNo = `${dayjs().format("YYMMDD")}-`;
    }
    this.dialogVisible = true;
  }

  private async doOkClick() {
    await (this.$refs["form"] as any).validate();
    try {
      const tasks: any[] = [];
      const datas: cls.SchoolContract[] = [];
      const hash = require("hash.js");
      for (let i = 0; i < this.schoolIds.length; i++) {
        const data = _.merge(new cls.SchoolContract(), this.formData);
        data.schoolId = this.schoolIds[i];
        data.schoolContractId = hash
          .sha1()
          .update(`${data.schoolId}-${this.formData.schoolContractNo}`)
          .digest("hex");
        //
        datas.push(data);
        tasks.push(SchoolApi.postSchoolContract(data));
      }
      //
      await Promise.all(tasks);
      //
      this.handleConfirmed(datas);
      this.dialogVisible = false;
    } catch (error: any) {
      this.$message({
        type: "error",
        message: error.message || error.msg || "保存失败。",
      });
    }
  }
}
